<template>
  <div id="returnDetail">
    <Loading v-if="loading"></Loading>
    <van-form>
      <van-cell-group inset>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.scName"
            name="scName"
            label="仓库"
            readonly
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.supplierName"
            name="supplierName"
            label="供应商"
            readonly
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            @click=""
            v-model="form.purchaserName"
            name="purchaserName"
            label="采购员"
            :readonly="curType === 'detail'"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.paymentDate"
            name="paymentDate"
            label="付款日期"
            :readonly="curType === 'detail'"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-permission="'purchase:receive:query'"
            v-model="form.receiveSheetCode"
            name="receiveSheetCode"
            label="采购收货单"
            readonly
          ></van-field>
          <van-field
            autocomplete="off"
            v-noPermission="'purchase:receive:query'"
            v-model="form.receiveSheetCode"
            name="receiveSheetCode"
            label="采购订单"
            readonly
          ></van-field>
        </van-cell>

        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.status"
            name="status"
            label="状态"
            readonly
            :formatter="formatter"
          ></van-field
        ></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.refuseReason"
            name="refuseReason"
            label="拒绝理由"
            readonly
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.createBy"
            name="createBy"
            label="操作人"
            readonly
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="操作时间"
            name="createTime"
            v-model:value="form.createTime"
            readonly
          >
          </van-field
        ></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="审核人"
            name="approveBy"
            readonly
            v-model:value="form.approveBy"
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            label="审核时间"
            name="approveTime"
            v-model:value="form.approveTime"
            readonly
          >
          </van-field
        ></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div v-if="curType != 'detail'" class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="showProductSelect = true"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <OrderTimeLine :id="id"></OrderTimeLine>
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="退货数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          readonly
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button
            type="primary"
            @click="toAction('approvePass')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'purchase:return:approve'"
            >审核通过</van-button
          >
          <van-button
            type="primary"
            @click="toAction('approveRefuse')"
            round
            v-permission="'purchase:return:approve'"
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            >审核拒绝</van-button
          >
          <van-button
            type="primary"
            @click="toAction('modify')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'purchase:return:modify'"
            >修改</van-button
          >
          <van-button
            type="warning"
            @click="toAction('del')"
            round
            v-if="(status == 0 || status == 6) && curType !== 'modify'"
            v-permission="'purchase:return:delete'"
            >删除</van-button
          >
          <van-button
            type="primary"
            @click="toAction('confirm')"
            round
            block
            v-if="(status == 0 || status == 6) && curType === 'modify'"
            >保存</van-button
          >
          <van-button @click="toAction('close')" type="info" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <van-dialog
      v-model="approveRefuseVisible"
      title="确认弹窗"
      show-cancel-button
      @cancel="cancelApproveRefuse"
      @confirm="confirmApproveRefuse"
    >
      <van-cell title="确认进行审批拒绝操作？"></van-cell>

      <van-cell v-model="refuseReason">
        <van-field
          autocomplete="off"
          v-model="refuseReason"
          rows="2"
          autosize
          type="textarea"
          label="拒绝理由"
        ></van-field>
      </van-cell>
    </van-dialog>
    <ProductSelect
      :scId="this.form.scId"
      columnsType="checkbox"
      :visible="showProductSelect"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import Loading from "../../../components/loading.vue";

import CustomTable from "../../../components/customTable.vue";
import OrderTimeLine from "../../../components/orderTimeLine.vue";
import ProductSelect from "../../../components/productSelect.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import {
  getTextByValue,
  PURCHASE_ORDER_STATUS,
} from "../../../utils/constants";
import {
  get,
  update,
  deleteById,
  approvePass,
  approveRefuse,
} from "../../../service/api/purchase/return";
import { getOrderTimeLine } from "../../../service/api";
import moment from "moment";
import { getPaymentDate } from "../../../service/api/purchase/receive";
export default {
  name: "index",
  components: {
    CustomTable,
    OrderTimeLine,
    ProductSelect,
    DateTimePicker,
    Loading,
  },
  data() {
    return {
      loading: true,
      showProductSelect: false,
      refuseReason: "",
      approveRefuseVisible: false,
      status: 0,
      curType: "detail",
      brandSelectVisible: false,
      id: this.$route.params.id,
      showDatePicker: false,
      statusList: [
        { text: "启用", value: "1" },
        { text: "停用", value: "0" },
      ],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },

        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field] ? "是" : "否";
            return <span>{text}</span>;
          },
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "purchasePrice",
          key: "purchasePrice",
          title: "采购价（元）",

          width: 100,
        },
        {
          field: "receiveNum",
          key: "receiveNum",
          title: "收货数量",
          width: 100,
        },
        {
          field: "remainNum",
          key: "remainNum",
          title: "剩余退货数量",
          width: 80,
        },
        {
          field: "returnNum",
          key: "returnNum",
          title: "退货数量",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="returnNum"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxRate",
          key: "taxRate",
          title: "税率（%）",
          width: 50,
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          width: 80,
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            return flag ? (
              ""
            ) : (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
      tableData: [],
      form: {
        id: "",
        name: "",
        retailPrice: "",
        categoryName: "",
        unit: "",
        externalCode: "",
      },
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      index: 0,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const res = await get(this.id);

      const data = res.data;
      console.log(data.paymentDate, "付款日期");
      this.form = Object.assign(this.form, {
        scId: data.scId,
        scName: data.scName,
        supplierId: data.supplierId,
        supplierName: data.supplierName,
        purchaserId: data.purchaserId || "",
        paymentDate: data.paymentDate || "",
        receiveSheetId: data.receiveSheetId,
        receiveSheetCode: data.receiveSheetCode,
        description: data.description,
        status: data.status,
        createBy: data.createBy,
        createTime: data.createTime,
        approveBy: data.approveBy,
        approveTime: data.approveTime,
        refuseReason: data.refuseReason,
        totalNum: 0,
        giftNum: 0,
        totalAmount: 0,
      });

      const tableData = data.details || [];
      tableData.forEach((item) => {
        item.isFixed = !this.$utils.isEmpty(item.receiveSheetDetailId);

        if (item.isFixed) {
          // 接口返回的剩余退货数量是最新的数据，应加上当前退货数量
          item.remainNum = this.$utils.add(item.remainNum, item.returnNum);
        }

        console.log(item.isFixed, item.remainNum);
        return item;
      });
      this.tableData = tableData.map((item) =>
        Object.assign(this.emptyProduct(), item)
      );

      this.supplierChange(this.form.supplierId);

      this.calcSum();
      this.status = this.form.status;
      this.calcSum();
      const orderTimeRes = await getOrderTimeLine({ orderId: this.id });
    } catch (error) {
      console.log(error);
      this.$router.push("/purchase/return");
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    emptyProduct() {
      return {
        id: this.$utils.uuid(),
        productId: "",
        productCode: "",
        productName: "",
        skuCode: "",
        externalCode: "",
        unit: "",
        spec: "",
        categoryName: "",
        brandName: "",
        purchasePrice: 0,
        taxCostPrice: "",
        stockNum: "",
        receiveNum: "",
        remainNum: "",
        returnNum: "",
        taxRate: "",
        isGift: true,
        taxAmount: "",
        description: "",
        isFixed: false,
        products: [],
      };
    },
    supplierChange(supplierId) {
      getPaymentDate(supplierId).then((res) => {
        if (res.allowModify) {
          // 如果允许修改付款日期
          if (this.$utils.isEmpty(this.form.paymentDate)) {
            this.form.paymentDate = res.data.paymentDate || "";
          }
        } else {
          // 不允许修改则按默认日期
          this.form.paymentDate = res.data.paymentDate || "";
        }

        this.form.allowModifyPaymentDate = res.data.allowModify;
      });
    },
    genParams() {
      const params = {
        id: this.id,
        scId: this.form.scId,
        supplierId: this.form.supplierId,
        purchaserId: this.form.purchaserId || "",
        paymentDate: this.form.paymentDate || "",
        receiveSheetId: this.form.receiveSheetId,
        description: this.form.description,
        products: this.tableData
          .filter((t) => this.$utils.isIntegerGtZero(t.returnNum))
          .map((t) => {
            const product = {
              productId: t.productId,
              returnNum: t.returnNum,
              description: t.description,
            };

            if (t.isFixed) {
              product.receiveSheetDetailId = t.receiveSheetDetailId;
            }

            return product;
          }),
      };
      return params;
    },

    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    onDatePickerConfirm(val) {
      val
        ? this.$set(this.tableData, this.index, {
            ...this.tableData[this.index],
            productionDate: val,
          })
        : "";
      this.showDatePicker = false;
      this.index = 0;
    },
    openDateTimePicker(index) {
      console.log(index, "点击了商品表格的某一行");
      this.index = index;
      this.showDatePicker = true;
    },
    // 校验数据
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.form.supplierId)) {
        this.$utils.createError("供应商不允许为空！");
        return false;
      }

      if (this.form.allowModifyPaymentDate) {
        if (this.$utils.isEmpty(this.form.paymentDate)) {
          this.$utils.createError("付款日期不允许为空！");
          return false;
        }
      }

      if (this.$utils.isEmpty(this.form.receiveSheetId)) {
        this.$utils.createError("采购收货单不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价不允许为空！");
          return false;
        }

        if (!this.$utils.isFloat(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价必须为数字！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.purchasePrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.purchasePrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.purchasePrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品采购价最多允许2位小数！"
          );
          return false;
        }

        if (!this.$utils.isEmpty(product.returnNum)) {
          if (!this.$utils.isInteger(product.returnNum)) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量必须为整数！"
            );
            return false;
          }

          if (product.isFixed) {
            if (!this.$utils.isIntegerGeZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量不允许小于0！"
              );
              return false;
            }
          } else {
            if (!this.$utils.isIntegerGtZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量必须大于0！"
              );
              return false;
            }
          }

          if (product.isFixed) {
            if (product.returnNum > product.remainNum) {
              this.$utils.createError(
                "第" +
                  (i + 1) +
                  "行商品累计退货数量为" +
                  (product.receiveNum - product.remainNum) +
                  "，剩余退货数量为" +
                  product.remainNum +
                  "，本次退货数量不允许大于" +
                  product.remainNum +
                  "！"
              );
              return false;
            }
          }
        } else {
          if (!product.isFixed) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量不允许为空！"
            );
            return false;
          }
        }
      }

      if (
        this.tableData.filter((item) =>
          this.$utils.isIntegerGtZero(item.returnNum)
        ).length === 0
      ) {
        this.$utils.createError("采购收货单中的商品必须全部或部分退货！");
        return false;
      }

      return true;
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.purchasePrice) &&
            this.$utils.isIntegerGeZero(t.returnNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.returnNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }

          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.purchasePrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }
      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
            taxRate: 0,
            taxCostPrice: 0.01,
            stockNum: 1,
            isGift: false,
            productCode: el.code,
            productId: el.id,
            productName: el.name,
            productionDate: "",
            shelfLifeDays: 0,
            shelfLife: "",
          });
        });
      }
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    confirmApproveRefuse() {
      approveRefuse({
        id: this.id,
        refuseReason: this.refuseReason,
      }).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/purchase/return" });
      });
    },
    cancelApproveRefuse() {
      this.approveRefuseVisible = false;
      this.refuseReason = "";
    },

    formatter(val) {
      return isNaN(val) ? val : getTextByValue(PURCHASE_ORDER_STATUS, val);
    },
    async toAction(action) {
      let promise = null;
      if (action === "close") {
        if (this.curType === "modify") {
          this.curType = "detail";
          return;
        }
        this.$router.push({ path: "/purchase/return" });
      } else if (action === "modify") {
        this.curType = "modify";
        return;
      } else if (action === "confirm") {
        //修改校验  todo
        if (!this.validData()) return;
        const params = this.genParams();
        update(params).then((res) => {
          Toast.success(res.msg);
          this.$router.push({ path: "/purchase/return" });
        });
      } else if (action === "del") {
        Dialog.confirm({
          title: "确认弹窗",
          message: "确定要删除该采购订单吗",
        })
          .then(async () => {
            promise = await deleteById({ id: this.id });
            Toast.success(promise.msg);
            this.$router.push({ path: "/purchase/return" });
          })
          .catch(() => {
            Toast.fail(promise.msg);
          });
      } else if (action === "approvePass") {
        Dialog.confirm({
          title: "确认弹窗",
          message: "确定要进行审批通过操作",
        })
          .then(async () => {
            promise = await approvePass({
              id: this.id,
            });
            Toast.success(promise.msg);
            this.$router.push({ path: "/purchase/return" });
          })
          .catch(() => {
            Toast.fail(promise.msg);
          });
      } else if (action === "approveRefuse") {
        this.approveRefuseVisible = true;
      }
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#returnDetail {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
